<template>
  <v-card>
    <v-card-title> Gerar Relatorio de todos os Objetos </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete
            outlined
            clearable
            label="Selecione o Tipo de Cliente"
            hint="Selecione o Tipo de Cliente"
            :items="getClientTypesSelect"
            persistent-hint
            @change="filterCli"
            v-model="report.clientType"
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            clearable
            outlined
            v-model="report.type"
            label="Selecione o tipo de dado"
            hint="Selecione o tipo de dado"
            :items="tipos"
            persistent-hint
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            clearable
            label="Data Inicial"
            placeholder="Placeholder"
            outlined
            type="date"
            v-model="report.start"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            clearable
            label="Data Final"
            placeholder="Placeholder"
            outlined
            type="date"
            v-model="report.end"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            clearable
            outlined
            chips
            small-chips
            multiple
            deletable-chips
            v-model="report.clientes"
            label="Selecione os Clientes"
            hint="Selecione os Clientes"
            :items="clientes"
            persistent-hint
            prepend-inner-icon="mdi-check-all"
            @click:prepend-inner="selecAll"
          >
            <template slot="no-data">
              <p class="mx-5 mt-2 mb-1">Sem dados para mostrar</p>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="0" outlined>
            <v-card-title> Manual de Uso </v-card-title>
            <v-card-text>
              <p>Primeiro selecione o tipo de Cliente</p>
              <p>Selecione o tipo de dado ou Relatório que lhe interresa</p>
              <p>
                Selecione o intervalo da pesquisa, a Data Inicial e a Data final
              </p>
              <p>
                Caso queira selecionar todos os clientes pode clicar no
                <v-icon>mdi-check-all</v-icon> no inicio do campo que todos os
                clientes serão marcados, caso contrario selecione os clientes
                individualmente ou ainda se desejar remover algum pode clicar
                no <v-icon>mdi-close-circle</v-icon> ao lado do nome do
                mesmo
              </p>
              <p>
                Caso queira limpar algum dos campos basta clicar no
                <v-icon>mdi-close</v-icon> no final do campo já preenchido
              </p>
              <p>
                Clique em Buscar Relatorio, sera gerado um excel com os dados
                Filtrados
              </p>
              <small class="red--text"
                >* Todos os campos são obrigatórios</small
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn @click="get" color="success">Buscar Relatorio</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "RelatoriosGerencialPage",
  computed: {
    ...mapGetters(["getClienteSelect", "getClientTypesSelect", "getClientes"]),
  },
  methods: {
    get() {
      this.$http({
        url: "relatorios/gerencial/excel",
        data: this.report,
        method: "POST",
        responseType: "blob", // important
      })
        .then((resp) => {
          if (resp.status == 200) {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ControleAcesso.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        })
        .catch(() => {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch(
            "showErrorSnack",
            "Campos em branco ou inválidos"
          );
        });
    },
    getClientesFilter() {
      if (!this.filter) {
        return this.getClienteSelect;
      }
      let clientes = [];
      this.getClientes.forEach((e) => {
        if (e.typeCli == this.filter) {
          clientes.push({ value: e.id, text: `${e.id} - ${e.name}` });
        }
      });
      return clientes;
    },
    filterCli(value) {
      if (value) {
        this.filter = value;
        this.clientes = this.getClientesFilter();
      }
    },
    selecAll() {
      this.report.clientes = this.clientes.map((e) => e.value);
    },
  },
  data: () => {
    return {
      clientes: [],
      report: { clientes: [], start: "", end: "", type: "" },
      tipo: "",
      tipos: [
        { value: 1, text: "Registros de Visitas" },
        { value: 2, text: "Registros de Visitados/Moradores" },
        { value: 3, text: "Registros de Visitantes" },
      ],
      filter: null,
    };
  },
  created() {
    this.$store.dispatch("loadClientes");
    this.$store.dispatch("loadClientTypes");
  },
};
</script>

<style>
</style>